import React from 'react'
import { graphql, Link } from 'gatsby'
import { Back, Heading, Layout, TextImage } from '../components'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'

type AboutPageTemplateProps = {
	title: string
}

const AboutPageTemplate: React.FC<AboutPageTemplateProps> = ({ title }) => {
	return (
		<>
			<Grid>
				<Row center={'md'}>
					<Col xs md={8}>
						<Back to={'/'} grid={false} />
						<Heading variant={'h1'} as={'h1'}>
							About Us
						</Heading>
						<p>
							Time is the one thing that we cannot get back. It is with this in mind that we designed our services to
							assist you with making the most of your precious time. Skip the line and in person wait time and do more
							of what you really want to do. We have created a platform through which you can easily access some of the
							best insurance intermediaries in the British Virgin Islands. Worry no more about traffic, finding parking
							or having to use most of your lunch hour to handle insurance matters. Let your fingers do the walking from
							the convenience of wherever you are, and in less than 10 mins you're done.
						</p>
						<br />
						{/*<Heading variant={'h3'} as={'h2'}>*/}
						{/*	St. Maarten*/}
						{/*</Heading>*/}
						{/*<Heading variant={'subheading'} as={'h3'}>*/}
						{/*	Best Advice Insurances*/}
						{/*</Heading>*/}
						{/*<p>*/}
						{/*	Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad architecto consequuntur dolorem dolorum eaque*/}
						{/*	enim excepturi facilis iusto molestiae nesciunt, nisi nostrum perferendis porro praesentium quis*/}
						{/*	repellendus, sint suscipit velit.*/}
						{/*</p>*/}
						{/*<Heading variant={'subheading'} as={'h3'}>*/}
						{/*	Northeastern Insurances*/}
						{/*</Heading>*/}
						{/*<p>*/}
						{/*	Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad architecto consequuntur dolorem dolorum eaque*/}
						{/*	enim excepturi facilis iusto molestiae nesciunt, nisi nostrum perferendis porro praesentium quis*/}
						{/*	repellendus, sint suscipit velit.*/}
						{/*</p>*/}
						{/*<Heading variant={'subheading'} as={'h3'}>*/}
						{/*	Rai Insurances*/}
						{/*</Heading>*/}
						{/*<p>*/}
						{/*	Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad architecto consequuntur dolorem dolorum eaque*/}
						{/*	enim excepturi facilis iusto molestiae nesciunt, nisi nostrum perferendis porro praesentium quis*/}
						{/*	repellendus, sint suscipit velit.*/}
						{/*</p>*/}
						{/*<Heading variant={'subheading'} as={'h3'}>*/}
						{/*	Sun Insurance*/}
						{/*</Heading>*/}
						{/*<p>*/}
						{/*	Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad architecto consequuntur dolorem dolorum eaque*/}
						{/*	enim excepturi facilis iusto molestiae nesciunt, nisi nostrum perferendis porro praesentium quis*/}
						{/*	repellendus, sint suscipit velit.*/}
						{/*</p>*/}
						{/*<br />*/}
						<Heading variant={'h3'} as={'h2'}>
							British Virgin Islands
						</Heading>
						<Heading variant={'subheading'} as={'h3'}>
							Company ABC
						</Heading>
						<p>
							Company ABC holds a [type of license] license issued by British Virgin Islands Financial Services
							Commission and has been in existence since [year]. The following types of insurance are available: [motor,
							property, marine, life and health/,medical, business]
						</p>
						<p>It represents the following insurers:</p>
						<ul>
							<li>Insurer 1</li>
							<li>Insurer 2</li>
							<li>Insurer 3</li>
							<li>Insurer 4</li>
						</ul>
						<Heading variant={'subheading'} as={'h3'}>
							Company DEF
						</Heading>
						<p>
							Company DEF holds a [type of license] license issued by British Virgin Islands Financial Services
							Commission and has been in existence since [year]. The following types of insurance are available: [motor,
							property, marine, life and health/,medical, business]{' '}
						</p>
						<p>It represents the following insurers:</p>
						<ul>
							<li>Insurer 1</li>
							<li>Insurer 2</li>
							<li>Insurer 3</li>
						</ul>
						<Heading variant={'subheading'} as={'h3'}>
							Company GHI
						</Heading>
						<p>
							Company GHI holds a [type of license] license issued by British Virgin Islands Financial Services
							Commission and has been in existence since [year]. The following types of insurance are available: [motor,
							property, marine, life and health/,medical, business]
						</p>
						<p>It represents the following insurers:</p>
						<ul>
							<li>Insurer 1</li>
							<li>Insurer 2</li>
							<li>Insurer 3</li>
						</ul>
					</Col>
				</Row>
			</Grid>
		</>
	)
}

type AboutPageProps = {
	data: {
		markdownRemark: {
			frontmatter: AboutPageTemplateProps
		}
	}
}

const AboutPage: React.FC<AboutPageProps> = ({
	data: {
		markdownRemark: { frontmatter },
	},
}) => {
	return (
		<Layout showRenewLinkOnMobile showFooterContact>
			<AboutPageTemplate {...frontmatter} />
		</Layout>
	)
}

const pageQuery = graphql`
	query AboutPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
			frontmatter {
				title
			}
		}
	}
`

export default AboutPage
export { AboutPageTemplate, pageQuery }
